import { InvoiceSettings } from '../../shared/types';
import { calculateTotal } from './calculations';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';

// Import fonts
import nexaHeavyFont from '../assets/fonts/Nexa-Heavy.ttf';
import verdanaRegularFont from '../assets/fonts/verdana.ttf';
import verdanaBoldFont from '../assets/fonts/verdana-bold.ttf';
import fs from 'fs';
import path from 'path';

const formatDate = (dateStr: string): string => {
  return dateStr.replace(/-/g, '/');
};

// Convert ArrayBuffer to binary string in chunks to avoid stack overflow
const arrayBufferToBinary = (buffer: ArrayBuffer): string => {
  const bytes = new Uint8Array(buffer);
  let binary = '';
  const chunkSize = 8192;
  
  for (let i = 0; i < bytes.length; i += chunkSize) {
    const chunk = bytes.slice(i, i + chunkSize);
    binary += String.fromCharCode.apply(null, chunk as unknown as number[]);
  }
  
  return binary;
};

const getMonthName = (date: Date): string => {
  return date.toLocaleString('en-US', { month: 'long' });
};

const generateFileName = (settings: InvoiceSettings): string => {
  // Create date at noon to avoid timezone issues
  const [year, month, day] = settings.startDate.split('-').map(Number);
  const startDate = new Date(year, month - 1, day, 12, 0, 0);
  const formattedMonth = String(startDate.getMonth() + 1).padStart(2, '0');
  const monthName = getMonthName(startDate);
  
  return `${year}-${month} ${settings.invoicerName} Hourly Invoice ${settings.clientName} ${monthName}${year}`;
};

export const generateInvoicePDF = async (settings: InvoiceSettings): Promise<void> => {
  const { laborCost, extra, totalCost, rsuAmount } = calculateTotal(settings);

  // Create new jsPDF instance
  const doc = new jsPDF();

  try {
    // Load fonts using fetch since webpack will handle the URLs
    const [nexaHeavy, verdanaRegular, verdanaBold] = await Promise.all([
      fetch(nexaHeavyFont).then(r => r.arrayBuffer()),
      fetch(verdanaRegularFont).then(r => r.arrayBuffer()),
      fetch(verdanaBoldFont).then(r => r.arrayBuffer())
    ]);

    // Convert ArrayBuffers to binary strings
    const nexaHeavyBinary = arrayBufferToBinary(nexaHeavy);
    const verdanaRegularBinary = arrayBufferToBinary(verdanaRegular);
    const verdanaBoldBinary = arrayBufferToBinary(verdanaBold);

    // Add fonts to PDF
    doc.addFileToVFS('Nexa-Heavy.ttf', nexaHeavyBinary);
    doc.addFileToVFS('verdana.ttf', verdanaRegularBinary);
    doc.addFileToVFS('verdana-bold.ttf', verdanaBoldBinary);

    doc.addFont('Nexa-Heavy.ttf', 'Nexa', 'bold');
    doc.addFont('verdana.ttf', 'Verdana', 'normal');
    doc.addFont('verdana-bold.ttf', 'Verdana', 'bold');

    // Title - INVOICE
    doc.setFontSize(15);
    doc.setFont('Nexa', 'bold');
    doc.text('INVOICE', 20, 15);

    // Invoicer details
    doc.setFontSize(10);
    doc.setFont('Nexa', 'bold');
    doc.text(settings.invoicerName, 120, 15);
    doc.setFontSize(8);
    doc.setFont('Verdana', 'normal');
    doc.text(settings.invoicerAddress.split('\n'), 120, 20);

    // Invoice details
    doc.setFontSize(8);
    doc.text(`Start Date: ${formatDate(settings.startDate)}`, 120, 30);
    doc.text(`End Date: ${formatDate(settings.endDate)}`, 120, 35);
    doc.text('Services Provided: ' + settings.serviceDescription, 120, 40, {
      maxWidth: 70,
      lineHeightFactor: 1
    });

    // Billed To section
    doc.setFontSize(10);
    doc.setFont('Nexa', 'bold');
    doc.text('Billed To:', 20, 25);
    doc.setFont('Verdana', 'normal');
    doc.setFontSize(8);
    doc.text(settings.clientName, 20, 30);
    doc.text(settings.clientAddress.split('\n'), 20, 35);
    doc.text(`EIN: ${settings.clientEIN}`, 20, 40);

    // Timesheet Link section
    doc.setFontSize(10);
    doc.setFont('Nexa', 'bold');
    doc.text('Link To Timesheet:', 20, 51);
    doc.setFont('Verdana', 'normal');
    doc.setFontSize(8);
    
    if (settings.timesheetUrl) {
      doc.setTextColor(0, 0, 255);
      doc.textWithLink(settings.timesheetUrl, 20, 56, {
        url: settings.timesheetUrl
      });
      doc.setTextColor(0, 0, 0);
    } else {
      doc.text('N/A', 20, 56);
    }

    // Cost Breakdown Section
    const startY = 75;
    const lineHeight = 5;
    
    // Section title
    doc.setFontSize(11);
    doc.setFont('Nexa', 'bold');
    doc.text('Cost Breakdown', 20, startY - 5);

    // Format service description with period
    const serviceDesc = `${settings.serviceDescription} for period: ${formatDate(settings.startDate)} - ${formatDate(settings.endDate)}`;

    let currentY = startY;

    // Main services table
    if (settings.singleLineCostMode) {
      // Single line mode with extra costs column
      autoTable(doc, {
        didDrawCell: function(data) {
          if (data.row.index === data.table.body.length - 1) {
            currentY = data.cell.y + data.cell.height + 5;
          }
        },
        startY: startY,
        head: [['Service Description', 'Hours', 'Rate', 'Extra Costs', 'Total']],
        body: [[
          serviceDesc,
          settings.hours,
          `$${settings.hourlyRate}`,
          settings.extraCosts.length > 0 ? `$${extra.toFixed(2)}` : '-',
          `$${totalCost.toFixed(2)}`
        ]],
        theme: 'grid',
        styles: {
          font: 'Verdana',
          fontStyle: 'normal',
          fontSize: 8,
          cellPadding: 3,
        },
        headStyles: {
          font: 'Nexa',
          fontStyle: 'bold',
          fontSize: 9,
          textColor: [0, 0, 0],
          fillColor: [178, 216, 198],
        },
        margin: { left: 20, right: 20 },
        columnStyles: {
          0: { cellWidth: 87 },
          1: { cellWidth: 20, halign: 'left' },
          2: { cellWidth: 20, halign: 'left' },
          3: { cellWidth: 20, halign: 'left' },
          4: { cellWidth: 22, halign: 'left' },
        },
      });
    } else {
      // Regular mode without extra costs column
      autoTable(doc, {
        didDrawCell: function(data) {
          if (data.row.index === data.table.body.length - 1) {
            currentY = data.cell.y + data.cell.height + 5;
          }
        },
        startY: startY,
        head: [['Service Description', 'Hours', 'Rate', 'Total']],
        body: [[
          serviceDesc,
          settings.hours,
          `$${settings.hourlyRate}`,
          `$${laborCost.toFixed(2)}`
        ]],
        theme: 'grid',
        styles: {
          font: 'Verdana',
          fontStyle: 'normal',
          fontSize: 8,
          cellPadding: 3,
        },
        headStyles: {
          font: 'Nexa',
          fontStyle: 'bold',
          fontSize: 9,
          textColor: [0, 0, 0],
          fillColor: [178, 216, 198],
        },
        margin: { left: 20, right: 20 },
        columnStyles: {
          0: { cellWidth: 107 },
          1: { cellWidth: 20, halign: 'left' },
          2: { cellWidth: 20, halign: 'left' },
          3: { cellWidth: 22, halign: 'left' },
        },
      });
    }

    // Only show extra costs table and total summary if there are extra costs and single line mode is disabled
    if (settings.extraCosts.length > 0 && !settings.singleLineCostMode) {
      // Extra Costs Table
      autoTable(doc, {
        didDrawCell: function(data) {
          if (data.row.index === data.table.body.length - 1) {
            currentY = data.cell.y + data.cell.height + 3;
          }
        },
        startY: currentY,
        head: [['Extra Item/Service Cost', 'Amount']],
        body: [
          ...settings.extraCosts.map(item => [
            item.title,
            `$${item.amount.toFixed(2)}`
          ]),
          ['Total Extra Costs', `$${extra.toFixed(2)}`]
        ],
        theme: 'grid',
        styles: {
          font: 'Verdana',
          fontStyle: 'normal' as const,
          fontSize: 8,
          cellPadding: 3,
        },
      headStyles: {
        font: 'Nexa',
        fontStyle: 'bold',
        fontSize: 9,
        textColor: [0, 0, 0],
        fillColor: [178, 216, 198], // Even lighter green color
      },
        margin: { left: 20, right: 20 },
        columnStyles: {
          0: { cellWidth: 147 },
          1: { cellWidth: 22, halign: 'left' },
        },
      });

      // Add minimal space before the total
      currentY += 2;

      // Total Summary Line with extra costs
      autoTable(doc, {
        startY: currentY,
        body: [[
          `Service cost ($${laborCost.toFixed(2)}) + Extra costs ($${extra.toFixed(2)}) =`,
          { content: `$${totalCost.toFixed(2)}`, styles: { fontStyle: 'bold' } }
        ]],
        theme: 'grid',
        styles: {
          font: 'Verdana',
          fontStyle: 'normal' as const,
          fontSize: 8,
          cellPadding: 3,
        },
        headStyles: {
          font: 'Nexa',
          fontStyle: 'bold' as const,
          fontSize: 9,
          textColor: [0, 0, 0],
        },
        margin: { left: 20, right: 20 },
        columnStyles: {
          0: { cellWidth: 147 },
          1: { cellWidth: 22, halign: 'left' },
        },
      });
    }

    // Add RSU breakdown section if RSU percentage is provided
    if (settings.rsuPercentage && settings.rsuPercentage !== '0') {
      currentY += 30; // Add more space before RSU section
      
      // Debug logging
      console.log('RSU Settings:', {
        rsuPercentage: settings.rsuPercentage,
        rsuBalanceBeforeTransfer: settings.rsuBalanceBeforeTransfer,
        totalCost,
        rsuAmount
      });

      const rsuPercentage = parseFloat(settings.rsuPercentage);
      const cashPercentage = 100 - rsuPercentage;
      const rsuAmountFormatted = rsuAmount.toFixed(2);
      const cashAmount = totalCost - rsuAmount;
      const balanceBeforeTransfer = settings.rsuBalanceBeforeTransfer 
        ? parseFloat(settings.rsuBalanceBeforeTransfer)
        : 0;
      const balanceAfterTransfer = balanceBeforeTransfer + rsuAmount;

      // Debug logging
      console.log('Calculated Values:', {
        rsuPercentage,
        cashPercentage,
        rsuAmountFormatted,
        cashAmount,
        balanceBeforeTransfer,
        balanceAfterTransfer
      });

      // RSU Section Title
      doc.setFontSize(11);
      doc.setFont('Nexa', 'bold');
      doc.text('RSU Breakdown', 20, currentY - 5);

      // Left table - RSU Breakdown
      autoTable(doc, {
        startY: currentY,
        margin: { left: 20, right: 20 },
        head: [['Equity Breakdown', 'Amount']],
        body: [
          ['RSU Percentage', `${rsuPercentage}%`],
          ['Invoice Total', `$${totalCost.toFixed(2)}`],
          [`${rsuPercentage}% of $${totalCost.toFixed(2)} =`, `$${rsuAmountFormatted}`],
          ['Prior Balance', `$${balanceBeforeTransfer.toFixed(2)}`],
          [{ content: 'New Balance', styles: { fontStyle: 'bold' as const, fillColor: [255, 252, 220] }}, 
           { content: `$${balanceAfterTransfer.toFixed(2)}`, styles: { fontStyle: 'bold' as const, fillColor: [255, 252, 220] }}]
        ],
        theme: 'grid' as const,
        styles: {
          font: 'Verdana',
          fontStyle: 'normal' as const,
          fontSize: 8,
          cellPadding: 3,
        },
        headStyles: {
          font: 'Nexa',
          fontStyle: 'bold' as const,
          fontSize: 9,
          textColor: [0, 0, 0],
          fillColor: [245, 245, 245]
        },
        columnStyles: {
          0: { cellWidth: 55 },
          1: { cellWidth: 25, halign: 'left' as const }
        }
      });

      // Right table - Cash Breakdown
      autoTable(doc, {
        startY: currentY,
        margin: { left: 110, right: 20 },
        head: [['Cash Breakdown', 'Amount']],
        body: [
          ['Cash Percentage', `${cashPercentage}%`],
          ['Invoice Total', `$${totalCost.toFixed(2)}`],
          [`${cashPercentage}% of $${totalCost.toFixed(2)} =`, `$${cashAmount.toFixed(2)}`],
          ['', ''],
          [{ content: 'Total cash owed now:', styles: { fontStyle: 'bold' as const, fillColor: [255, 252, 220] }}, 
           { content: `$${cashAmount.toFixed(2)}`, styles: { fontStyle: 'bold' as const, fillColor: [255, 252, 220] }}]
        ],
        theme: 'grid' as const,
        styles: {
          font: 'Verdana',
          fontStyle: 'normal' as const,
          fontSize: 8,
          cellPadding: 3,
        },
        headStyles: {
          font: 'Nexa',
          fontStyle: 'bold' as const,
          fontSize: 9,
          textColor: [0, 0, 0],
          fillColor: [245, 245, 245]
        },
        columnStyles: {
          0: { cellWidth: 55 },
          1: { cellWidth: 25, halign: 'left' as const }
        }
      });
    }

    // Add message at the bottom if provided
    if (settings.message) {
      currentY += settings.rsuPercentage && settings.rsuPercentage !== '0' ? 70 : 20; // Add less space after sections
      
      doc.setFontSize(10);
      doc.setFont('Verdana', 'bold');
      doc.text(settings.message, 20, currentY, {
        maxWidth: 170,
        lineHeightFactor: 1.5
      });
    }

    // Save the PDF with the generated filename
    doc.save(`${generateFileName(settings)}.pdf`);
  } catch (error: any) {
    console.error('Error loading fonts:', error);
    throw new Error(`Error loading fonts: ${error.message}`);
  }
};
